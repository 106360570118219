import {
  Backdrop,
  Button, Card, CardContent, Fade, makeStyles, Modal,
} from '@material-ui/core';
import { useEffect, useState } from 'react';
import DataTable from '../../../components/DataTable/components/DataTable';
import { api } from '../../../services/api';
import { NewSupplierForm } from './NewSupplierForm';
import './SuppliersReport.scss';

const headers = [
  {
    id: 'name', numeric: false, disablePadding: false, label: 'Nome', align: 'center',
  },
  {
    id: 'deliveryTime', numeric: true, disablePadding: true, label: 'Tempo de entrega', align: 'left',
  },
  {
    id: 'paymentMethod', numeric: false, disablePadding: true, label: 'Método de pagamento', align: 'left',
  },
  {
    id: 'lastOrderDate', numeric: false, disablePadding: true, label: 'Data do último pedido', align: 'left',
  },
  {
    id: 'lastDeliveryDate', numeric: false, disablePadding: true, label: 'Data da última entrega', align: 'left',
  },
  {
    id: 'actions', numeric: true, disablePadding: false, label: 'Ações', align: 'center',
  },
];

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

export function SuppliersReport() {
  const [rows, setRows] = useState([]);
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('id');
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalElements, setTotalElements] = useState(0);
  const [loading, setLoading] = useState(false);
  const [supplierToEdit, setSupplierToEdit] = useState(null);
  const [userModalOpen, setSupplierModalOpen] = useState(false);
  const classes = useStyles();

  const fetchData = async () => {
    try {
      setLoading(true);
      const { data } = await api.get('suppliers', {
        params: {
          size: rowsPerPage,
          page,
          sort: `${orderBy},${order}`,
        },
      });

      setTotalElements(data.totalElements);
      setRows(data.content.map((supplier) => ({
        name: supplier.name,
        deliveryTime: supplier.deliveryTime || '',
        paymentMethod: supplier.paymentMethod || '',
        lastOrderDate: supplier.lastOrder || '',
        lastDeliveryDate: supplier.lastDelivery || '',
        id: supplier.id,
      })));
      setLoading(false);
    } catch (err) {
      console.log(err);
    }
  };

  const handleOpen = () => {
    setSupplierToEdit(null);
    setSupplierModalOpen(true);
  };

  const handleEditClick = (event) => {
    setSupplierToEdit(event);
    setSupplierModalOpen(true);
  };

  const handleClose = (update = false) => {
    setSupplierModalOpen(false);

    if (update) {
      fetchData();
    }
  };

  useEffect(() => {
    fetchData();
  }, [page, rowsPerPage, orderBy, order]);

  return (
    <div className="suppliers-report">
      <Card className="search-container">
        <CardContent>
          <div className="button-group" style={{ justifyContent: 'space-between' }}>
            <Button variant="contained" size="large" onClick={handleOpen}>
              Novo Fornecedor
            </Button>

            <Button
              variant="contained"
              size="large"
              type="submit"
              onSubmit={(e) => { e.preventDefault(); fetchData(); }}
            >
              Buscar
            </Button>
          </div>
        </CardContent>

      </Card>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={userModalOpen}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={userModalOpen}>
          <div className={classes.paper}>
            <NewSupplierForm handleClose={handleClose} supplierToEdit={supplierToEdit} />
          </div>
        </Fade>
      </Modal>

      <DataTable
        headers={headers}
        rows={rows}
        page={page}
        setPage={setPage}
        order={order}
        setOrder={setOrder}
        orderBy={orderBy}
        setOrderBy={setOrderBy}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        totalElements={totalElements}
        hideUpdateAction
        hideDeleteAction
        handleEditClick={handleEditClick}
        selectableRecords={false}
        loading={loading}
      />
    </div>

  );
}
