import {
  Assignment, BarChart, Home, People, Settings, TrendingUp, List,
} from '@material-ui/icons';

const Itens = [
  {
    name: 'Home',
    icon: <Home />,
    route: '/home',
  },
  {
    name: 'Produtos sem vendas',
    icon: <BarChart />,
    route: '/relatorios/produtos-sem-vendas',
  },
  {
    name: 'Produtos',
    icon: <List />,
    route: '/relatorios/produtos',
  },
  {
    name: 'Estoque de Segurança',
    icon: <Assignment />,
    route: '/relatorios/estoque-de-segunca',
  },
  {
    name: 'Relatório de atualizações',
    icon: <TrendingUp />,
    route: '/relatorios/atualizacoes',
    onlyManager: true,
  },
  {
    name: 'Relatório de atualizações por usuário',
    icon: <TrendingUp />,
    route: '/relatorios/atualizacoes-por-usuario',
    onlyManager: true,
  },
  {
    name: 'Relatório de fornecedores',
    icon: <People />,
    route: '/relatorios/fornecedores',
    onlyManager: true,
  },
  // {
  //   name: 'Relatório 3',
  //   icon: <PieChart />,
  //   route: '/relatorio-3',
  // },
  {
    name: 'Configurações',
    icon: <Settings />,
    route: '/configuracoes',
  },
];

export default Itens;
