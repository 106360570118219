import { useEffect, useState } from 'react';
import {
  Button,
  Card,
  CardContent,
  FormControl,
  InputAdornment,
  InputLabel,
  ListItemText,
  MenuItem, Select, TextField,
} from '@material-ui/core';
import { Search } from '@material-ui/icons';
import DateRangePicker from '@wojtekmaj/react-daterange-picker/dist/DateRangePicker';
import { saveAs } from 'file-saver';
import useDidMountEffect from '../../../utils/useDidMountEffect';
import DataTable from '../../../components/DataTable/components/DataTable';

import { api } from '../../../services/api';

import './SecurityStock.scss';
import currencyFormat from '../../../utils/currencyFormat';
import { BlingProductService } from '../../../services/blingProduct.service';
import { SupplierService } from '../../../services/suppliers.service';

const headers = [
  {
    id: 'code', numeric: false, disablePadding: true, label: 'Código', align: 'left',
  },
  {
    id: 'name', numeric: false, disablePadding: true, label: 'Descriçao do Produto', align: 'left',
  },
  {
    id: 'quantityStored', numeric: true, disablePadding: false, label: 'Quantidade em Estoque', align: 'center',
  },
  {
    id: 'price', numeric: true, disablePadding: false, label: 'Preço', align: 'center',
  },
  {
    id: 'curve', numeric: false, disablePadding: false, label: 'Curva ABC', align: 'center',
  },
  {
    id: 'quantitySales', numeric: true, disablePadding: false, label: 'Saídas', align: 'center',
  },
  {
    id: 'monthlyAverage', numeric: true, disablePadding: false, label: 'Média mensal', align: 'center',
  },
  {
    id: 'dailyAverage', numeric: true, disablePadding: false, label: 'Média diária', align: 'center',
  },
  {
    id: 'daily', numeric: true, disablePadding: false, label: 'Diário', align: 'center',
  },
  {
    id: 'deliveryTime', numeric: true, disablePadding: false, label: 'Prazo', align: 'center',
  },
  {
    id: 'securityStock', numeric: true, disablePadding: false, label: 'Estoque segurança', align: 'center',
  },
  {
    id: 'requiredItens', numeric: true, disablePadding: false, label: 'Ponto de reposição', align: 'center',
  },
  {
    id: 'total', numeric: true, disablePadding: false, label: 'Total', align: 'center',
  },
  {
    id: 'actions', numeric: true, disablePadding: false, label: 'Ações', align: 'center',
  },
];

function SecurityStock() {
  const [rows, setRows] = useState([]);
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('id');
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalElements, setTotalElements] = useState(0);
  const [loading, setLoading] = useState(false);
  const [nameQuery, setNameQuery] = useState('');
  const [codeQuery, setCodeQuery] = useState('');
  const [dateRange, setDateRange] = useState([
    new Date(new Date() - (86400000 * 30)),
    new Date(),
  ]);
  const [filtersChanged, setFiltersChanged] = useState(false);
  const [suppliers, setSuppliers] = useState([]);
  const [supplierIdQuery, setSupplierIdQuery] = useState(1);
  const [daysProjectionQuery, setDaysProjectionQuery] = useState(15);

  const fetchData = async (supplierId = supplierIdQuery) => {
    try {
      setLoading(true);

      const response = await BlingProductService.getSafetyStock({
        size: rowsPerPage,
        page: filtersChanged ? 0 : page,
        sort: `${orderBy},${order}`,
        code: codeQuery,
        name: nameQuery,
        supplierId,
        startDate: dateRange ? dateRange[0] : undefined,
        endDate: dateRange ? dateRange[1] : undefined,
        projectionDays: daysProjectionQuery,
      });

      setTotalElements(response.totalElements);
      setRows(response.content);
      setLoading(false);
    } catch (err) {
      console.log(err);
    }
  };

  const fetchSuppliers = async () => {
    try {
      setLoading(true);
      const { data } = await SupplierService.getSuppliers({
        size: 200,
        page: 0,
      });

      const filtered = data.content.filter((i) => i.name !== '');

      setSupplierIdQuery(filtered[0].id);
      setSuppliers(filtered.map((supplier) => ({
        id: supplier.id,
        name: supplier.name,
      })));

      setLoading(false);

      fetchData(filtered[0].id);
    } catch (err) {
      console.log(err);
    }
  };

  const exportData = async () => {
    try {
      const { data } = await BlingProductService.exportSafetyStock({
        size: rowsPerPage,
        page: filtersChanged ? 0 : page,
        sort: `${orderBy},${order}`,
        code: codeQuery,
        name: nameQuery,
        supplierId: supplierIdQuery,
        startDate: dateRange ? dateRange[0] : undefined,
        endDate: dateRange ? dateRange[1] : undefined,
        projectionDays: daysProjectionQuery,
      });

      saveAs(data, 'safety.xlsx');
    } catch (err) {
      console.log(err);
    }
  };

  useDidMountEffect(() => {
    setFiltersChanged(true);
  }, [nameQuery, codeQuery, dateRange, supplierIdQuery]);

  useEffect(async () => {
    await fetchSuppliers();
    await fetchData();
  }, []);

  useEffect(async () => {
    // await fetchSuppliers();
    fetchData();
  }, [page, rowsPerPage, orderBy, order]);

  const updateItem = async (products) => {
    try {
      setLoading(true);
      const { data } = await api.post('bling-products/markAsUpdated', {
        ids: products,
      });

      console.log(data);
    } catch (err) {
      console.log(err);
    } finally {
      fetchData();
    }
  };

  return (
    <div>
      <Card className="search-container">
        <CardContent>
          <form className="report-form" onSubmit={(e) => { e.preventDefault(); fetchData(); }}>
            <DateRangePicker onChange={setDateRange} value={dateRange} />

            <TextField
              placeholder="Codigo"
              variant="outlined"
              size="small"
              value={codeQuery}
              onChange={(e) => setCodeQuery(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                ),
              }}
            />

            <TextField
              placeholder="Descrição do produto"
              variant="outlined"
              size="small"
              value={nameQuery}
              onChange={(e) => setNameQuery(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                ),
              }}
            />

            <FormControl size="small" className="select-control">
              <InputLabel id="supplier-label label">Fornecedor</InputLabel>
              <Select
                labelId="supplier-label"
                label="Fornecedor"
                className="supplier-select"
                id="supplier"
                name="supplier"
                variant="outlined"
                value={supplierIdQuery}
                onChange={(e) => setSupplierIdQuery(e.target.value)}
              >

                {suppliers.map((type) => (
                  <MenuItem key={type.id} value={type.id}>
                    <ListItemText primary={type.name} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl size="small" className="select-control">
              <InputLabel id="days-projection-label label">Projeção para reposição</InputLabel>
              <Select
                labelId="days-projection-label"
                label="Projeção para reposição"
                className="days-projection-select"
                id="days-projection"
                name="days-projection"
                variant="outlined"
                value={daysProjectionQuery}
                onChange={(e) => setDaysProjectionQuery(e.target.value)}
              >
                <MenuItem key="7" value={7}><ListItemText primary="7" /></MenuItem>
                <MenuItem key="15" value={15}><ListItemText primary="15" /></MenuItem>
                <MenuItem key="30" value={30}><ListItemText primary="30" /></MenuItem>
                <MenuItem key="45" value={45}><ListItemText primary="45" /></MenuItem>
                <MenuItem key="60" value={60}><ListItemText primary="60" /></MenuItem>
              </Select>
            </FormControl>

            <div className="button-group end" style={{ gap: 10 }}>
              <Button variant="contained" size="large" type="submit">
                Buscar
              </Button>

              <Button variant="contained" size="large" type="button" onClick={() => exportData()}>
                Exportar
              </Button>
            </div>
          </form>
        </CardContent>
      </Card>

      <DataTable
        headers={headers}
        rows={rows}
        page={page}
        setPage={setPage}
        order={order}
        setOrder={setOrder}
        orderBy={orderBy}
        setOrderBy={setOrderBy}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        totalElements={totalElements}
        handleUpdateItem={updateItem}
        loading={loading}
        hideUpdateAction
        hideEditAction
        hideDeleteAction
        selectableRecords={false}
      />
    </div>
  );
}
export default SecurityStock;
