import {
  Dialog, DialogTitle, Table, TableContainer,
} from '@material-ui/core';
import EnhancedTableHead from '../../DataTable/components/EnhancedTableHead/EnhancedTableHead';
import EnhancedTableBody from '../../DataTable/components/EnhancedTableBody/EnhancedTableBody';

export function CostChangeHistory(props) {
  const {
    openDialog, history, handleCloseDialog,
  } = props;

  const headers = [
    {
      id: 'date', numeric: false, disablePadding: true, label: 'Data', align: 'left',
    },
    {
      id: 'previousCost', numeric: true, disablePadding: true, label: 'Preço Anterior', align: 'left',
    },
    {
      id: 'newCost', numeric: true, disablePadding: true, label: 'Novo Preço', align: 'left',
    },
    {
      id: 'eventSent', numeric: true, disablePadding: true, label: 'Evento Enviado para integração', align: 'left',
    },
  ];

  return (
    <Dialog open={openDialog} onClose={() => handleCloseDialog()}>
      <DialogTitle id="alert-dialog-title">Histórico de preços</DialogTitle>

      <TableContainer>
        <Table>
          <EnhancedTableHead
            cells={headers}
            selectableRecords={false}
            rowCount={history.length}
          />
          <EnhancedTableBody
            headers={headers}
            rows={history.map((e) => ({
              date: new Date(e.date).toLocaleDateString(),
              previousCost: e.previousCost,
              newCost: e.newCost,
              eventSent: !!e.integrationEventId,
            }))}
            page={0}
            rowsPerPage={10}
            selected={[]}
            selectableRecords={false}
            hideUpdateAction
            hideEditAction
            hideDeleteAction
          />
        </Table>
      </TableContainer>
    </Dialog>
  );
}
