import {
  Button,
  Card, CardContent, CircularProgress, Collapse, IconButton,
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { Alert } from '@material-ui/lab';
import { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { api } from '../../services/api';
import { StoreContext } from '../../store';

function BlingAuthorizationPage() {
  const [isLoading, setIsLoading] = useState(true);
  const [alertOpen, setAlertOpen] = useState(true);
  const [alertSuccess, setAlertSuccess] = useState(false);
  const [alertMessage, setalertMessage] = useState('');
  const { user, setUser } = useContext(StoreContext);
  const history = useHistory();

  const postBlingAuthorization = async () => {
    try {
      const searchParams = new URLSearchParams(window.location.search);
      const code = searchParams.get('code');
      const state = searchParams.get('state');

      const response = await api.post('auth/bling-auth/authorize', { code, state });

      if (response.status === 200) {
        setIsLoading(false);
        setAlertSuccess(true);
        setAlertOpen(true);
        setalertMessage('Autenticado com sucesso! Por favor, aguarde alguns minutos enquanto realizamos a importação de seus produtos');

        setUser({ ...user, company: { ...user.company, integration: response.data } });
      }
    } catch (error) {
      console.log('Error: ', error);

      setIsLoading(false);
      setAlertSuccess(false);
      setAlertOpen(true);
      setalertMessage('Erro na autorização com a Bling! Por favor, tente novamente');
    }
  };

  useEffect(() => {
    postBlingAuthorization();
  }, []);

  return (
    <div className="bling-authorization-container">
      <Card>
        <CardContent>
          {
            isLoading
              ? (
                <div style={{ height: 80 }}>
                  <CircularProgress style={{ position: 'absolute', left: '51%' }} />
                </div>
              )
              : (
                <>
                  <Collapse in={alertOpen}>
                    <Alert
                      style={{ marginBottom: 20 }}
                      severity={alertSuccess ? 'success' : 'error'}
                      action={(
                        <IconButton
                          aria-label="close"
                          color="inherit"
                          size="small"
                          onClick={() => {
                            setAlertOpen(false);
                          }}
                        >
                          <Close fontSize="inherit" />
                        </IconButton>
                      )}
                    >
                      {alertMessage}
                    </Alert>
                  </Collapse>

                  {alertSuccess && (
                    <div className="button-group">
                      <Button variant="contained" size="large" type="submit" onClick={() => history.replace('/home')}>
                        Voltar para Home
                      </Button>
                    </div>
                  )}
                </>
              )
          }
        </CardContent>
      </Card>
    </div>
  );
}

export default BlingAuthorizationPage;
