import currencyFormat from '../utils/currencyFormat';
import { api } from './api';

export class BlingProductService {
  static async getSafetyStock(parameters) {
    const {
      size, page, sort, code, name, supplierId, startDate, endDate, projectionDays,
    } = parameters;

    const { data } = await api.get('bling-products/safety-stock', {
      params: {
        size,
        page,
        sort,
        code,
        name,
        supplierId,
        startDate,
        endDate,
        projectionDays,
      },
    });

    return {
      totalElements: data.totalElements,
      content: data.content.map((item) => ({
        code: item.code,
        name: item.name,
        quantityStored: item.quantityStored,
        price: currencyFormat(item.price),
        curve: item.curve,
        quantitySales: item.quantitySales,
        monthlyAverage: item.monthlyAverage,
        dailyAverage: Number(item.dailyAverage).toFixed(9),
        daily: Number(item.daily).toFixed(9),
        deliveryTime: item.deliveryTime,
        securityStock: item.securityStock,
        requiredItens: item.requiredItens,
        total: currencyFormat(item.total),
      })),
    };
  }

  static async exportSafetyStock(parameters) {
    const {
      size, page, sort, code, name, supplierId, startDate, endDate, projectionDays,
    } = parameters;

    return api.get('bling-products/safety-stock/export', {
      params: {
        size,
        page,
        sort,
        code,
        name,
        supplierId,
        startDate,
        endDate,
        projectionDays,
      },
      responseType: 'blob',
    });
  }

  static async exportGetAllProducts(parameters) {
    const {
      codeQuery, nameQuery, curve,
    } = parameters;

    return api.get('bling-products/export', {
      params: {
        code: codeQuery,
        name: nameQuery,
        curve,
      },
      responseType: 'blob',
    });
  }
}
