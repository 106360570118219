import { useContext, useState, useEffect } from 'react';
import {
  BrowserRouter, Route, Switch, Redirect,
} from 'react-router-dom';
import { useHistory } from 'react-router';
import { Header } from '../components/Header';
import { Drawer } from '../components/Drawer';

import { Dashboard } from './Dashboard';
import { ProductsWithoutSales } from './ProductsWithoutSales';
import { SecurityStock } from './SecurityStock';
import { Login } from './Login';
import { Settings } from './Settings';

import { StoreContext } from '../store';
import { ChangePassword } from './ChangePassword';

import { api } from '../services/api';
import { UpdatesReport } from './UpdatesReport/components/UpdatesReport';
import { UpdatesReportByUser } from './UpdatesByUserReport';
import { SuppliersReport } from './Suppliers/components/SuppliersReport';
import { ProductsReport } from './ProductsReport/components/ProductsReport';
import SetupIntegrationPage from './SetupIntegration/SetupIntegrationPage';
import BlingAuthorizationPage from './BlingAuthorization/BlingAuthorizationPage';

const PrivateRoute = ({ component: Component, ...rest }) => {
  const history = useHistory();
  const { user, token, webOpen } = useContext(StoreContext);
  const [authenticated, setAuthenticated] = useState(true);

  function handleUnauthorizedUser() {
    history.replace('/');
  }

  api.interceptors.response.use(undefined, (err) => {
    if ([401, 403].indexOf(err.response.status) !== -1 && !err.response.config.url.includes('auth')) {
      handleUnauthorizedUser();
    }

    return Promise.reject(err);
  });

  const isAuthenticated = () => authenticated && token && user;
  const isMissingIntegration = (props) => isAuthenticated() && !user.company.integration && !['/setup-integration', '/bling-authorization'].includes(props.location.pathname);

  async function getAuthentication() {
    try {
      console.log('runnign auth');
      const { data } = await api.get('users/validate-token');
      setAuthenticated(data);
    } catch (error) {
      setAuthenticated(false);
    }
  }

  useEffect(() => {
    getAuthentication();
  }, []);

  const renderRoute = (props) => {
    if (isAuthenticated()) {
      if (isMissingIntegration(props)) {
        return (
          <Redirect to={{ pathname: '/setup-integration', state: { from: props.location } }} />
        );
      }

      return (
        <>
          <Header />
          <Drawer />
          <div className={`app ${!webOpen ? 'drawer-web-closed' : ''}`}>
            <Component {...props} />
          </div>
        </>
      );
    }

    return (
      <Redirect to={{ pathname: '/', state: { from: props.location } }} />
    );
  };

  return (
    <Route
      {...rest}
      render={(props) => (renderRoute(props))}
    />
  );
};

const Routes = () => (
  <BrowserRouter>
    <Switch>
      <Route exact path="/" component={() => <Login />} />
      <Route path="/trocar-minha-senha" component={() => <ChangePassword />} />
      <PrivateRoute path="/home" component={() => <Dashboard />} />
      <PrivateRoute path="/setup-integration" component={() => <SetupIntegrationPage />} />
      <PrivateRoute path="/bling-authorization" component={() => <BlingAuthorizationPage />} />
      <PrivateRoute path="/relatorios/produtos-sem-vendas" component={() => <ProductsWithoutSales />} />
      <PrivateRoute path="/relatorios/estoque-de-segunca" component={() => <SecurityStock />} />
      <PrivateRoute path="/relatorios/atualizacoes" component={() => <UpdatesReport />} />
      <PrivateRoute path="/relatorios/atualizacoes-por-usuario" component={() => <UpdatesReportByUser />} />
      <PrivateRoute path="/relatorios/fornecedores" component={() => <SuppliersReport />} />
      <PrivateRoute path="/relatorios/produtos" component={() => <ProductsReport />} />
      <PrivateRoute path="/configuracoes" component={() => <Settings />} />
      <Route path="*" component={() => <h1>Page not found</h1>} />
    </Switch>
  </BrowserRouter>
);

export default Routes;
