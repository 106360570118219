import {
  Button,
  Collapse,
  FormControl,
  FormHelperText,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { Alert } from '@material-ui/lab';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { useFormik } from 'formik';
import { useState } from 'react';
import InputMask from 'react-input-mask/lib/react-input-mask.development';
import * as yup from 'yup';
import MomentUtils from '@date-io/moment';
import { api } from '../../../services/api';

export function NewSupplierForm(props) {
  const [alertOpen, setAlertOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');

  const [lastOrder, setLastOrder] = useState(null);
  const [lastDelivery, setLastDelivery] = useState(null);

  const { supplierToEdit, handleClose } = props;

  const validationSchema = yup.object({
    name: yup
      .string('Insira o Nome')
      .required('Nome é obrigatório'),
    deliveryTime: yup
      .number('Insira o tempo de entrega'),
    paymentMethod: yup
      .string('Insira o método de pagamento'),
    // lastOrder: yup
    //   .string('Insira o Último pedido')
    //   .default(new Date()),
    // lastDelivery: yup
    //   .string('Insira a Último entrega')
    //   .default(new Date()),
  });

  async function handleSubmit(supplierData) {
    try {
      setIsLoading(true);
      const { data } = await api.post('suppliers', { ...supplierData });

      if (data.name) {
        setAlertMessage('Fornecedor criado com sucesso!');
        setAlertOpen(true);
        setIsLoading(false);

        setTimeout(() => {
          handleClose(true);
        }, 1500);
      }
    } catch (err) {
      setAlertMessage(err.response && err.response.data
        ? err.response.data
        : 'Erro no cadastro! Por favor, tente novamente');
      setAlertOpen(true);
      setIsLoading(false);
    }
  }

  const formik = useFormik({
    initialValues: {
      name: supplierToEdit?.name ?? '',
      deliveryTime: supplierToEdit?.deliveryTime ?? '',
      paymentMethod: supplierToEdit?.paymentMethod ?? '',
      lastOrder: supplierToEdit?.lastOrder ?? new Date(),
      lastDelivery: supplierToEdit?.lastDelivery ?? new Date(),
    },
    validationSchema,
    onSubmit: (values) => handleSubmit(!supplierToEdit ? values : { ...supplierToEdit, ...values }),
  });

  return (
    <form onSubmit={(e) => {
      e.preventDefault();
      formik.submitForm();
    }}
    >
      <Collapse in={alertOpen}>
        <Alert
          style={{ marginBottom: 20 }}
          severity={alertMessage === 'Salvo com sucesso!' ? 'success' : 'error'}
          action={(
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setAlertOpen(false);
              }}
            >
              <Close fontSize="inherit" />
            </IconButton>
          )}
        >
          {alertMessage}
        </Alert>
      </Collapse>

      <div className="row">
        <TextField
          id="name"
          name="name"
          label="Nome"
          variant="outlined"
          size="small"
          value={formik.values.name}
          onChange={formik.handleChange}
          error={formik.touched.name && Boolean(formik.errors.name)}
          helperText={formik.touched.name && formik.errors.name}
        />

      </div>

      <div className="row">
        <TextField
          id="deliveryTime"
          name="deliveryTime"
          label="Tempo de Entrega"
          variant="outlined"
          size="small"
          value={formik.values.deliveryTime}
          onChange={formik.handleChange}
          error={formik.touched.deliveryTime && Boolean(formik.errors.deliveryTime)}
          helperText={formik.touched.deliveryTime && formik.errors.deliveryTime}
        />

        <TextField
          id="paymentMethod"
          name="paymentMethod"
          label="Método de pagamento"
          variant="outlined"
          size="small"
          value={formik.values.paymentMethod}
          onChange={formik.handleChange}
          error={formik.touched.paymentMethod && Boolean(formik.errors.paymentMethod)}
          helperText={formik.touched.paymentMethod && formik.errors.paymentMethod}
        />
      </div>

      <div className="row">
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <KeyboardDatePicker
            disableToolbar
            variant="inline"
            format="DD/MM/yyyy"
            margin="normal"
            id="lastOrder"
            label="Último pedido"
            value={lastOrder}
            onChange={(date) => setLastOrder(date)}
            KeyboardButtonProps={{
              'aria-label': 'change date',
            }}
          />

          <KeyboardDatePicker
            disableToolbar
            variant="inline"
            format="DD/MM/yyyy"
            margin="normal"
            id="lastDelivery"
            label="Última entrega"
            value={lastDelivery}
            onChange={(date) => setLastDelivery(date)}
            KeyboardButtonProps={{
              'aria-label': 'change date',
            }}
          />
        </MuiPickersUtilsProvider>
      </div>

      <div className="button-group">
        <Button variant="contained" size="large" type="submit" disabled={isLoading}>
          {supplierToEdit ? 'Atualizar' : 'Cadastrar'}
        </Button>
      </div>
    </form>
  );
}
