import {
  Button,
  Card, CardContent, CardHeader, Radio, Step, StepContent, StepLabel, Stepper, Typography,
  makeStyles,
} from '@material-ui/core';
import './SetupIntegration.scss';
import { useState } from 'react';
import { api } from '../../services/api';

const steps = ['Selecione um dos provedores abaixo para prosseguir com sua ativação', 'Prossiga com a ativação'];
const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  actionsContainer: {
    marginBottom: theme.spacing(2),
  },
  resetContainer: {
    padding: theme.spacing(3),
  },
}));

function SetupIntegrationPage() {
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(0);
  const [selectedIntegration, setSelectedIntegration] = useState('bling');
  const integrationProviders = [
    {
      name: 'bling',
      logo: 'https://vancecontabil.com.br/wp-content/uploads/2021/06/logo-bling-png-1.png',
    },
  ];

  const handleBackStep = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleChangeProvider = (event) => {
    setSelectedIntegration(event.target.value);
  };

  const redirectToBlingAuthUrl = async () => {
    const { data: url } = await api.post('auth/bling-auth/initiate');

    window.location.href = url;
  };

  const handleNextStep = () => {
    if (activeStep === 1 && selectedIntegration === 'bling') {
      redirectToBlingAuthUrl();
      return;
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <div className="providers">
            {integrationProviders.map((provider) => (
              <div className="selectable-provider">
                <img className="provider" src={provider.logo} alt="provider-logo" />

                <Radio
                  checked={selectedIntegration === provider.name}
                  onChange={handleChangeProvider}
                  value={provider.logo}
                  name="radio-button-demo"
                />
              </div>
            ))}
          </div>
        );
      case 1:
        // if (selectedIntegration === 'bling') {
        return (
          <div className="bling-auth">
            <Typography>
              Para prosseguir, precisamos que você autorize
              nosso aplicativo parceiro com sua conta Bling.
              <br />
              <br />
            </Typography>
            <Typography>
              Ao clicar no botão
              <strong> Finalizar</strong>
              , iremos te redirecionar para a página
              da Bling para realizar a autorização.
              <br />
              Após isso, você voltará para nosso site e estará tudo pronto!
            </Typography>

          </div>
        );
        // }
      default:
        return (<Typography>Erro! Por favor recarregue a página</Typography>);
    }
  };

  return (
    <div className="integration-container">
      <Card>
        <CardHeader title="Detectamos que você não possui nenhuma integração ativa com um ERP!" />
        <CardContent>
          <Stepper activeStep={activeStep} orientation="vertical">
            {steps.map((label, index) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
                <StepContent>
                  {getStepContent(index)}
                  <div className={classes.actionsContainer}>
                    <div>
                      <Button
                        disabled={activeStep === 0}
                        onClick={handleBackStep}
                        className={classes.button}
                      >
                        Voltar
                      </Button>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleNextStep}
                        className={classes.button}
                      >
                        {activeStep === steps.length - 1 ? 'Finalizar' : 'Próximo'}
                      </Button>
                    </div>
                  </div>

                </StepContent>
              </Step>
            ))}
          </Stepper>
        </CardContent>
      </Card>
    </div>
  );
}

export default SetupIntegrationPage;
