import { api } from './api';

export class SupplierService {
  static async getSuppliers(parameters) {
    const { size, page } = parameters;

    return api.get('suppliers', {
      params: {
        size,
        page,
      },
    });
  }
}
